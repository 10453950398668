import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery, Link } from "gatsby";

import NavToggle from "./navToggle";
import Nav from "./nav";
import Logo from "./logo";

const Header = ({ currentSlug }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          subTitle
        }
      }
    }
  `);

  const [showNav, setShowNav] = useState(false);

  const handleNavToggle = (e) => {
    if (showNav) {
      setShowNav(false);
    } else if (!showNav) {
      setShowNav(true);
    }
  };

  return (
    <header>
      <Helmet bodyAttributes={{ class: showNav ? "overlay" : "" }} />
      <div className="container relative flex flex-col xl:flex-row justify-between items-center xl:items-end bg-vip-tan">
        <div className="brand w-full sm:w-auto flex items-center">
          <Link to="/" className="flex items-center">
            <Logo id="logo" />
            <div className="brand-name flex flex-col pl-4">
              <span
                className="text-xl md:text-3xl text-vip-purple"
                style={{ marginBottom: "0" }}
              >
                {data.site.siteMetadata.title}
              </span>
              <span className="text-2xs md:text-sm lg:text-base">
                {data.site.siteMetadata.subTitle}
              </span>
            </div>
          </Link>
          <div className="absolute right-6">
            <NavToggle status={showNav} onClick={handleNavToggle} />
          </div>
        </div>
        <Nav status={showNav} />
      </div>
    </header>
  );
};

export default Header;
