import * as React from 'react';

const Paw = ({ color, style }) => {
  if (!color) color = '#000000';

  return (
    <svg version="1.1" viewBox="0.0 0.0 290.5301837270341 299.5853018372703" fill="none" stroke="none" style={style}>
      <g>
        <path fill="#000000" fillOpacity="0.0" d="m0 0l290.53018 0l0 299.5853l-290.53018 0z" fillRule="evenodd" />
        <path
          fill={color}
          d="m3.8892982 156.12367l0 0c-4.986187 -29.401222 4.988924 -55.413315 22.280033 -58.09964l0 0c8.303486 -1.2900162 17.218071 3.081543 24.782654 12.152977c7.564583 9.071434 13.159512 22.099663 15.553959 36.218636l0 0c4.986191 29.401215 -4.988922 55.4133 -22.280033 58.099625l0 0c-17.29111 2.686325 -35.350426 -18.970383 -40.336613 -48.371597z"
          fillRule="evenodd"
        />
        <path
          fill={color}
          d="m223.00844 146.59631l0 0c11.88092 -26.971703 34.513626 -42.760933 50.55153 -35.26622l0 0c7.70166 3.599083 12.821442 12.19603 14.233002 23.899597c1.41156 11.703583 -1.0007324 25.555084 -6.7061462 38.507355l0 0c-11.88092 26.97171 -34.51361 42.76094 -50.551514 35.266235l0 0c-16.037918 -7.494705 -19.407806 -35.435257 -7.5268707 -62.406967z"
          fillRule="evenodd"
        />
        <path
          fill={color}
          d="m66.073 65.33764l0 0c-1.5278091 -34.255245 13.023506 -62.451725 32.501274 -62.9786l0 0c9.353554 -0.25301313 18.615463 6.039054 25.748215 17.492018c7.132744 11.452965 11.552071 27.128658 12.285744 43.57861l0 0c1.5278015 34.255245 -13.023506 62.451725 -32.501274 62.978603l0 0c-19.477768 0.5268707 -36.50615 -26.815384 -38.03396 -61.070633z"
          fillRule="evenodd"
        />
        <path
          fill={color}
          d="m158.08339 60.75677l0 0c2.8217163 -34.094486 20.860382 -60.103554 40.290512 -58.09288l0 0c9.330673 0.96556044 17.74089 8.395623 23.380524 20.65566c5.639633 12.260036 8.046692 28.345768 6.6916504 44.718517l0 0c-2.8217163 34.09449 -20.860382 60.10356 -40.290512 58.09288l0 0c-19.430115 -2.0106735 -32.89389 -31.279694 -30.072174 -65.374176z"
          fillRule="evenodd"
        />
        <path
          fill={color}
          d="m140.61032 136.07599c8.80426 -0.58143616 17.466171 -0.4852295 25.332657 7.857132c7.866501 8.342377 9.956955 26.752365 21.866333 42.197083c11.909378 15.444717 40.285767 35.931732 49.589935 50.471222c9.304184 14.539505 7.8235016 27.87175 6.235153 36.76573c-1.5883636 8.893982 -8.317505 13.344696 -15.765289 16.598145c-7.4477844 3.253418 -17.109238 4.1009827 -28.921463 2.9224854c-11.812225 -1.1785278 -24.130722 -10.665894 -41.951874 -9.993561c-17.821152 0.67230225 -48.78804 13.608978 -64.975 14.027405c-16.18695 0.4184265 -24.58377 -4.9613953 -32.14672 -11.516876c-7.5629463 -6.555481 -13.358955 -16.397552 -13.230957 -27.815979c0.12799835 -11.4184265 5.539257 -26.67131 13.998955 -40.694626c8.459694 -14.023315 28.013344 -31.866531 36.759224 -43.445282c8.745888 -11.578735 8.51458 -19.798386 15.716087 -26.027191c7.2015 -6.228821 18.688683 -10.764267 27.492958 -11.345688z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default Paw;
