import * as React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import classNames from "classnames";
import { ClientOnly } from "react-client-only";

const Nav = ({ status }) => {
  const data = useStaticQuery(graphql`
    query {
      allMenuJson {
        nodes {
          to
          name
          subNav {
            to
            name
          }
        }
      }
    }
  `);

  var mainNav = addLinkStatus(data.allMenuJson.nodes);

  return (
    <ClientOnly>
      <nav
        className={classNames(
          "absolute lg:static w-full lg:w-auto top-full lg:top-auto bg-vip-tan lg:bg-transparent",
          { open: status }
        )}
      >
        <ul className="flex flex-col lg:flex-row m-0">
          {mainNav.map((link, index) => {
            return (
              <li className="nav-item" key={index}>
                {link.to === "#" ? (
                  <span
                    className={classNames("nav-link no-to cursor-default", {
                      active: link.isActive,
                      direct: link.isDirect,
                    })}
                  >
                    {link.name}
                  </span>
                ) : (
                  <Link
                    to={link.to}
                    className={classNames("nav-link", {
                      active: link.isActive,
                      direct: link.isDirect,
                    })}
                  >
                    {link.name}
                  </Link>
                )}
                {link.subNav && link.subNav.length > 0 && (
                  <SubNavMenu items={link.subNav} />
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </ClientOnly>
  );
};

const SubNavMenu = ({ items }) => {
  return (
    <ul className="subNav">
      {items.map((link, index) => {
        return (
          <li className="subNav-item" key={index}>
            <Link
              to={link.to}
              className={classNames("subNav-link", {
                active: link.isActive,
                direct: link.isDirect,
              })}
            >
              {link.name}
            </Link>
            {link.subNav && <SubNavMenu items={link.subNav} />}
          </li>
        );
      })}
    </ul>
  );
};

function addLinkStatus(navItems) {
  var route = typeof window !== "undefined" ? window.location.pathname : "";
  navItems.map((link) => {
    if (route === "/" || route === "") {
      link.isActive = false;
      link.isDirect = false;
      link.subNav?.map((subLink) => {
        subLink.isActive = false;
        subLink.isDirect = false;
        return subLink;
      });
      return link;
    }

    if (link.subNav) {
      var hasActiveSubLink = false;
      link.subNav.map((subLink) => {
        if (route === subLink.to) {
          subLink.isActive = true;
          subLink.isDirect = true;
          hasActiveSubLink = true;
        } else {
          subLink.isActive = false;
          subLink.isDirect = false;
        }
        return subLink;
      });
      link.isActive = hasActiveSubLink || route === link.to;
      link.isDirect = route === link.to;
    } else {
      link.isActive = link.isDirect = route === link.to;
    }
    return link;
  });
  return navItems;
}

export default Nav;
