import * as React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';

import favicon from '../images/favicon.ico';
// import Covid from "./covid";
import Header from './header';
import Footer from './footer';

const Layout = ({ slug, title, description, meta, noContainer, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          subTitle
          description
        }
      }
    }
  `);

  var pageClass = slug === '/' ? 'index' : slug;
  // var route = `/${slug}/`;

  var baseTitle = `${data.site.siteMetadata.title} ${data.site.siteMetadata.subTitle}`;

  var metaDescription = classNames(
    data.site.siteMetadata.description,
    description
  );

  return (
    <div id="gatsby__app">
      <Helmet
        defaultTitle={baseTitle}
        title={title}
        titleTemplate={`%s - ${baseTitle}`}
        htmlAttributes={{
          lang: 'en',
        }}
        bodyAttributes={{
          class: [`page-${pageClass}`],
        }}
        meta={[
          {
            name: 'description',
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:image`,
            content: '/logo.png',
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          // {
          //   name: `twitter:creator`,
          //   content: site.siteMetadata.author,
          // },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat(meta)}
        link={[
          {
            rel: 'icon',
            href: favicon,
          },
        ]}
      />
      <div className="z-50">
        {/* <Covid /> */}
        <Header />
      </div>
      <main
        className={classNames({ 'pt-6': !noContainer, 'pb-16': !noContainer })}
      >
        {noContainer ? children : <div className="container">{children}</div>}
      </main>
      <Footer />
      <div id="overlay"></div>
    </div>
  );
};

Layout.defaultProps = {
  meta: [],
};

export default Layout;
