import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import moment from 'moment';

import Paw from './paw';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      image1: file(relativePath: { eq: "wnw_certified_clear.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 150, placeholder: NONE)
        }
      }
    }
  `);

  return (
    <footer className="bg-vip-beige">
      <div className="flex flex-col items-center mt-8 mb-10">
        <Paw color="#fffbed" style={{ width: '40px' }} />
      </div>
      <div className="container flex flex-wrap sm:mb-4">
        <div className="w-full sm:w-1/2 text-center sm:text-right mb-4 sm:mb-0 sm:pr-4 sm:border-r border-vip-dark-gray">
          <span className="inline-block mb-4">
            Phone:&nbsp;403-740-4833
            <br />
            Email:&nbsp;laura.vipets@yahoo.ca
          </span>
          <div className="flex justify-center sm:justify-end">
            <a
              href="https://www.facebook.com/laura.vipets"
              target="_blank"
              rel="noreferrer"
            >
              <span className="sr-only">Facebook</span>
              <FontAwesomeIcon icon={faFacebookSquare} size="3x" />
            </a>
            <a
              href="https://www.instagram.com/vipetsboarding/"
              target="_blank"
              rel="noreferrer"
              className="ml-3"
            >
              <span className="sr-only">Instagram</span>
              <FontAwesomeIcon icon={faInstagram} size="3x" />
            </a>
          </div>
        </div>
        <div className="flex justify-center sm:justify-start w-full sm:w-1/2 mb-4 sm:mb-0 sm:pl-4">
          <a href="http://walksnwags.com/" target="_blank" rel="noreferrer">
            <GatsbyImage
              image={getImage(data.image1)}
              alt="Walks N' Wags Logo"
              className="mb-4"
            />
          </a>
        </div>
      </div>

      <div className="container mb-4 flex flex-col items-center">
        <span>
          Copyright &copy; {moment().format('Y')} -{' '}
          {data.site.siteMetadata.title}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
