import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";

const NavToggle = ({ status, onClick }) => {
  return (
    <button
      id="navToggle"
      className="lg:hidden"
      onClick={onClick}
      aria-label="Toggle Navigation"
    >
      <FontAwesomeIcon icon={status ? faTimes : faBars} size="2x" />
    </button>
  );
};

export default NavToggle;
